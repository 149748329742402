<template>

<!-- 个人信息页下的收藏夹里的商标盒子 -->
<div @click="choose" class="box">
  <img class="picture" title="问标网" alt="问标网" src="../../imger/WBWlogo01.jpg" />
  <div class="bottom">
    <p class="lanmu">
      <span class="bottomtitle">村村有福</span>
      <span class="bottomclass">第42类<img src="../../imger/userSCJ/tuceng07.png" id="icon02" alt=""></span>
    </p>
    <p class="lanmu">
      <el-button class="bot01" type="primary" plain>在线询价</el-button>
      <el-button class="bot01" type="primary" >立即沟通</el-button>
    </p>
  </div>

  <!-- 选择时box盒子要显示的样子 -->
  <div  v-show="boxstate01" class="Choose">
    <img src="../../imger/userSCJ/tuceng04.png" alt="" class="ChooseIcon01">
    <img src="../../imger/userSCJ/tuceng05.png" alt="" class="ChooseIcon02">
  </div>
  <!-- 不可选择时box盒子要显示的样子 -->
  <div  v-show="boxstate02" class="Choose">
    <img src="../../imger/userSCJ/tuceng06.png" alt="" class="ChooseIcon01">
    <img src="../../imger/userSCJ/tuceng05.png" alt="" class="ChooseIcon02">
  </div>
</div>
  
</template>

<script>
export default {
  data(){
    return {
      boxstate01:false,
      boxstate02:false
    }
  },
  methods:{
    choose(){
      if(this.boxstate01==false){
        this.boxstate01 = true
      }else {
        this.boxstate01 = false
      }
      
    },
  }
}
</script>

<style scoped lang="scss">
#icon02 {
  margin-left: 0.3125rem /* 5px -> 0.3125rem */;
}
.box {
  width: 12.75rem /* 204px -> 12.75rem */;
  height: 11.48125rem /* 183.7px -> 11.48125rem */;
  margin:  0.5rem /* 8px -> 0.5rem */;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  .picture {
    width: 100%;
  }
  .bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .lanmu {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .el-button {
        min-height: 1.25rem /* 20px -> 1.25rem */;
        height: 1.25rem /* 20px -> 1.25rem */;
        width: 3.9875rem /* 63.8px -> 3.9875rem */;
        font-size: 0.625rem /* 10px -> 0.625rem */;
        margin: 0;
        line-height: 0;
        display: flex;
        justify-content: center;
      }
      .bottomtitle {
        font-size: 0.886875rem /* 14.19px -> 0.886875rem */;
        color: #333333;
      }
      .bottomclass {
        font-size: 0.726875rem /* 11.63px -> 0.726875rem */;
        color: #666666;
      }
    }
  }

  .Choose {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 99;
    .ChooseIcon01 {
      position: absolute;
      right: 0;
      top: 0;
      width: 3.9375rem /* 63px -> 3.9375rem */;
      height: 3.9375rem /* 63px -> 3.9375rem */;
    }
    .ChooseIcon02 {
        position: absolute;
        right: 0.5rem /* 8px -> 0.5rem */;
        top: 0.5rem /* 8px -> 0.5rem */;
        width: 1.4375rem /* 23px -> 1.4375rem */;
        height: 1.0625rem /* 17px -> 1.0625rem */;
      }
  }
  
}
</style>