<template>
  <!-- 关联企业 -->
    <div class="menuContent01" v-show="index==2? true:false">
        <div class="header">
          <p class="title">关联企业</p>
          <p class="outlogin" @click="fn2()">
            <img src="../../imger/userIfon/tuichu.png" alt="退出登录——问标网" class="out">
            <span>退出登录</span>
          </p>
        </div>
        <hr>
        <div class="wxApplet">
          <img src="../../imger/WBWlogo01.jpg" title="问标网" alt="问标网" class="icon01">
          <img src="../../imger/xcx.png" alt="问标网免费查商标" class="icon02">
          <p class="wxAppletWord">为更好体验，请微信扫码访问小程序</p>
        </div>
      </div>
</template>

<script>
export default {
  props:['boxindex'],
  inject: ["reload"],
  data(){
    return {
      index:this.boxindex,
    }
  },
  watch:{
    boxindex(curVal,oldVal){
      switch(curVal){
        case 0:
          this.index = 0
          break;
        case curVal:
          this.index = curVal
          break;
        default :
          this.index = 0
          break;
      }
    }
  },
  methods:{
    // 退出登录
    fn2(){
      this.$confirm('是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '账号已退出，请重新登录！',
          });
          this.$router.push({path:'/'})
          sessionStorage.clear()
          localStorage.removeItem('user')
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '出错了，请重新尝试'
          });          
        });
    }
  }
}
</script>

<style scoped lang="scss">
hr{
  background-color: #F0F0F0;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border:none;
}
.menuContent01 {
    width: 58.125rem /* 930px -> 58.125rem */;
    background-color: #fff;
    padding-top: 1.125rem /* 18px -> 1.125rem */;
    padding-bottom: 1.125rem /* 18px -> 1.125rem */;
    padding-left: 1.5625rem /* 25px -> 1.5625rem */;
    padding-right: 1.5625rem /* 25px -> 1.5625rem */;
    box-sizing: border-box;
    .header {
      width: 100%;
      height: 3.125rem /* 50px -> 3.125rem */;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #333333;
      }
      .outlogin {
        font-size: 0.875rem /* 14px -> 0.875rem */;
        color: #B7B7B7;
        display: flex;
        cursor: pointer;
        .out {
          width: 1.25rem /* 20px -> 1.25rem */;
          height: 1.25rem /* 20px -> 1.25rem */;
        }
      }
    }
    .wxApplet {
      width: 100%;
      height: 25rem /* 400px -> 25rem */;
      padding-top: 1.875rem /* 30px -> 1.875rem */;
      padding-bottom: 1.875rem /* 30px -> 1.875rem */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .icon01 {
        width: 7.0625rem /* 113px -> 7.0625rem */;
      }
      .icon02 {
        width: 8.4375rem /* 135px -> 8.4375rem */;
        height: 8.4375rem /* 135px -> 8.4375rem */;
      }
      .wxAppletWord {
        font-size: 0.9375rem /* 15px -> 0.9375rem */;
        color: #666666;
      }
    }
  }
</style>