<template>
<!-- 信息左右栏内容区域 -->
  <div class="Information">
    <div class="side">
      <!-- 用户头像区域 -->
      <div class="user">
        <img class="picture" alt="用户头像——问标网" src="../../imger/userIfon/toux01.png"/>
        <div class="name">{{userInfo.name}}</div>
      </div>

      <!-- sidebar的菜单内容 -->
      <div class="sideContent">
        <div class="box" 
        v-for="(item,index) in boxdata" :key="index" 
        :style="{backgroundColor: index==boxindex? '#EDF4FF':'#fff'}"
        @click="checkout(index)">
          <div class="lan" :style="{backgroundColor: index==boxindex? '#4D89F1':'#fff'}"></div>
          <img :src="index==boxindex? item.boxicon02 : item.boxicon01" :alt="item.boxname" class="boxicon">
          <div class="boxname"
          :style="{color: index==boxindex? '#4D89F1':'#333333'}"
          >{{item.boxname}}</div>
        </div>
      </div>
    </div>

    <!-- 菜单信息框 -->
      <MenuContent01 :boxindex="boxindex" :userInfoData="userInfo" :userkey="userkey"/>
      <MenuContent02 :boxindex="boxindex" 
      :userAddress="userAddress" 
      @fn1="fn1" 
      :length="length"
      :userkey="userkey"/>
      <MenuContent03 :boxindex="boxindex"/>
      <!-- <MenuContent04 :boxindex="boxindex"/> -->
    </div>
</template>

<script>
// box被触发之后变换的图片
import icon01 from '../../imger/userIfon/user01.png'
import icon02 from '../../imger/address/address07.png'
import icon03 from '../../imger/userIfon/qy04.png'
// import icon04 from '../../imger/userSCJ/scj02.png'
// 不触发时的图片
import icon01C from '../../imger/address/user.png'
import icon02C from '../../imger/userIfon/address07.png'
import icon03C from '../../imger/userIfon/qy03.png'
// import icon04C from '../../imger/userIfon/scj01.png'
// 对应左侧栏菜单的右侧栏内容
import MenuContent01 from "./menuContent01.vue";
import MenuContent02 from "./menuContent02.vue";
import MenuContent03 from "./menuContent03.vue";
import MenuContent04 from "./menuContent04.vue";

export default {
  props:["userInfoData","userAddress","userkey","length"],
  components:{
    MenuContent01,
    MenuContent02,
    MenuContent03,
    MenuContent04,
  },
  data(){
    return {
      boxdata:[
        {
          boxname:"个人信息",
          boxicon01:icon01C,
          boxicon02:icon01,
        },
        {
          boxname:"收件地址",
          boxicon01:icon02C,
          boxicon02:icon02,
        },
        {
          boxname:"关联企业",
          boxicon01:icon03C,
          boxicon02:icon03,
        },
        // {
        //   boxname:"收藏夹",
        //   boxicon01:icon04C,
        //   boxicon02:icon04,
        // }
      ],
      boxindex:0,   //决定点击反馈的哪个盒子
      userInfo:this.userInfoData
    }
  },
  watch:{
    userInfoData(newData,oldData){
      if(newData){
        this.userInfo = newData
      }
    }
  },
  methods:{
    checkout(index){
      this.boxindex = index
      this.$emit("checkout",index)
    },
    fn1(){
      this.$emit("fn1")
    }
  }
}
</script>

<style scoped lang="scss">
.Information {
  width: 100%;
  display: flex;
  justify-content:space-between;
  .side {
    width: 15.5625rem /* 249px -> 15.5625rem */;
    height: 30.875rem /* 494px -> 30.875rem */;
    background-color: #fff;
    .user {
      width: 15.5625rem /* 249px -> 15.5625rem */;
      height: 8.25rem /* 132px -> 8.25rem */;
      background-color: #E6EFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      .picture {
        width: 4.5rem /* 72px -> 4.5rem */;
        height: 4.5rem /* 72px -> 4.5rem */;
      }
      .name{
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #333333;
        margin-left: 0.75rem /* 12px -> 0.75rem */;
      }
    }
    .sideContent {
      width: 100%;
      margin-top: 1.125rem /* 18px -> 1.125rem */;
      .box {
        width: 100%;
        height: 2.375rem /* 38px -> 2.375rem */;
        margin-top: 0.625rem /* 10px -> 0.625rem */;
        display: flex;
        align-items: center;
        cursor: pointer;
        .lan {
          width: 0.1875rem /* 3px -> 0.1875rem */;
          height: 100%;
          margin-right: 1.5625rem /* 25px -> 1.5625rem */;
        }
        .boxicon {
          width: 1.125rem /* 18px -> 1.125rem */;
          height: 1.25rem /* 20px -> 1.25rem */;
          margin-right: 1.0625rem /* 17px -> 1.0625rem */; 
        }
        .boxname {
          font-size: 1rem /* 16px -> 1rem */;
        }
      }
    }
  }
}

</style>