<template>
  <div class="user">
    <!-- 个人用户信息 -->

    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

    <!-- 个人用户功能 -->
    <div class="index">
      <div class="content">
        <Info
          :userInfoData="userInfoData"
          @checkout="checkout"
          :userAddress="userAddress"
          :length="length"
          @fn1="fn1"
          :userkey="userkey"
        />
      </div>
    </div>

    <!-- 扫码小程序弹窗 -->
    <div v-show="login" class="Popup">
      <div class="Popupbox">
        <div class="logoPicture">
          <img src="../imger/WBWlogo01.jpg" title="问标网" alt="问标网" class="signinicon01" />
          <img src="../imger/xcx.png" alt="问标网免费查询商标" class="signinicon02" />
          <p class="signinword01">微信扫码访问小程序</p>
        </div>
        <!-- 下方的关闭按钮 -->
        <div class="signinBoxClose">
          <img
            src="../imger/tranClassGroup/group111.png"
            alt="问标网"
            class="signinicon03"
            @click="close"
          />
        </div>
      </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import Info from "../components/userInformation/Information.vue";

import { userInfo, addressInfo } from "../API/user";
import { keyCode, newIP } from "../API/index";

export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Info,
  },
  data() {
    return {
      login: true,
      phone: "13788640502",
      userId: 17,
      userData: "", //存放本地储存的user数据
      userInfoData: "", //存放userInfo接口的数据
      userAddress: "", //存放addressInfo接口的数据
      userkey: "",
      length: 0,
      ip: "",
    };
  },
  async created() {
    await this.keyCode();
    await this.userInfo();
  },

  methods: {
    //  获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      // 事前要做的事
      this.userData = JSON.parse(localStorage.getItem("user"));
      this.userkey = sessionStorage.getItem("userkey");
      // 判断是否登录
      if (!this.userkey) {
        this.userkey = sessionStorage.getItem("key");
      }

      let duan = sessionStorage.getItem("key");
      if (duan == "" || duan == null || duan == undefined) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },

    // 查询个人信息
    async userInfo() {
      const res = await userInfo({
        key: this.userkey,
        phone: this.userData.phone,
        user_id: this.userData.id,
      });
      if (res.data.key) {
        this.userInfoData = res.data.user_data;
        await sessionStorage.setItem("userkey", res.data.key);
      }
    },

    close() {
      this.login = false;
    },

    checkout(index) {
      switch (index) {
        case 1:
          this.fn1();
          break;
        default:
          break;
      }
    },

    // 加载收件地址内容数据
    async fn1() {
      const res = await addressInfo({
        key: this.userkey,
        phone: this.userData.phone,
        user_id: this.userData.id,
      });
      if (res.data.key) {
        await sessionStorage.setItem("userkey", res.data.key);
        if (res.data.consignee_address != null) {
          this.userAddress = res.data.consignee_address;
          this.length = res.data.consignee_address.length;
        } else {
          this.userAddress = [];
          this.length = 0;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.user {
  width: 100%;
  height: 100%;
  position: relative;
  .index {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    .content {
      width: 74.875rem /* 1198px -> 74.875rem */;
      margin-top: 2.1875rem /* 35px -> 2.1875rem */;
      margin-bottom: 3.625rem /* 58px -> 3.625rem */;
    }
  }
  .Popup {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .Popupbox {
      width: 23.75rem /* 380px -> 23.75rem */;
      height: 31.25rem /* 500px -> 31.25rem */;
      display: flex;
      flex-direction: column;

      .logoPicture {
        width: 100%;
        height: 24.5625rem /* 393px -> 24.5625rem */;
        background-color: #eef7ff;
        border-radius: 0.625rem /* 10px -> 0.625rem */;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .signinicon01 {
          width: 9.4375rem /* 151px -> 9.4375rem */;
        }
        .signinicon02 {
          width: 11.8125rem /* 189px -> 11.8125rem */;
          height: 11.8125rem /* 189px -> 11.8125rem */;
          border: 0.0625rem /* 1px -> 0.0625rem */ dashed #aaaaaa;
        }
        .signinword01 {
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #333333;
        }
      }
      .signinBoxClose {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .signinicon03 {
          width: 2.125rem /* 34px -> 2.125rem */;
          height: 2.125rem /* 34px -> 2.125rem */;
        }
      }
    }
  }
}
</style>