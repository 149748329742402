<template>
  <div>
    <!-- 个人信息 -->
    <div class="menuContent01" v-show="index == 0 ? true : false">
      <div class="header">
        <p class="title">个人信息</p>
        <p class="outlogin"  @click="fn2()">
          <img src="../../imger/userIfon/tuichu.png" alt="退出登录——问标网" class="out" />
          <span>退出登录</span>
        </p>
      </div>
      <hr />
      <!-- 信息字段 -->
      <div class="infoBox">
        <div class="field">头像</div>
        <img class="fieldContent" alt="用户头像——问标网" src="../../imger/userIfon/toux01.png" />
      </div>
      <div class="infoBox">
        <div class="field">昵称</div>
        <p class="fieldWord">{{ userInfoData.name }}</p>
        <el-button class="fieldmodify" type="text" @click="ter">修改</el-button>
      </div>
      <div class="infoBox">
        <div class="field">手机号</div>
        <p class="fieldWord">{{ userInfoData.phone }}</p>
      </div>
      <div class="infoBox">
        <div class="field">常用邮箱</div>
        <p class="fieldWord">{{ userInfoData.email }}</p>
      </div>
      <div class="infoBox">
        <div class="field">实名认证</div>
        <p
          class="unfieldWord"
          :style="{ color: card == true ? '#4D89F1' : '#B7B7B7' }"
        >
          {{ card == true ? "已认证" : "未认证" }}
        </p>
        <p class="fieldmodify">{{ card == true ? "" : "去认证" }}</p>
      </div>
      <div class="infoBox">
        <div class="field">其他联系方式</div>
        <p class="fieldWord">{{ userInfoData.other_phone }}</p>
      </div>
    </div>

    <!-- 修改点击反馈消息提示框 -->
    <el-dialog title="修改信息" :visible.sync="showModal01" width="37.5rem">
      <div class="infoBox">
        <div class="field">昵称</div>
        <el-input
          v-model="inputData01"
          :placeholder="userInfo.name==''? '请输入昵称':userInfo.name"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox">
        <div class="field">常用邮箱</div>
        <el-input
          v-model="inputData02"
          :placeholder="userInfo.email==''? '请输入常用邮箱':userInfo.email"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox">
        <div class="field">其他联系方式</div>
        <el-input
          v-model="inputData03"
          :placeholder="userInfo.other_phone==''? '请输入其他联系方式':userInfo.other_phone"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox">
        <div class="field">手机号</div>
        <el-input
          v-model="phone"
          placeholder="请输入该账号绑定的手机号"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox">
        <div class="field">验证码</div>
        <div class="passwordbox">
          <input
            class="password"
            type="text"
            placeholder="请输入验证码"
            v-model="code"
          />
          <el-button class="obtain" @click="obtaincode" :disabled="disabled">{{
            codetext
          }}</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal01 = false">取 消</el-button>
        <el-button type="primary" @click="loging()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userInfoModify } from "../../API/user";
import { sengOut, receive } from "../../API/login";
export default {
  inject: ["reload"],
  props: ["boxindex", "userInfoData", "userkey"],

  data() {
    return {
      index: this.boxindex,
      userInfo: this.userInfoData,
      card: false,
      newName: "",
      // username:'',
      // userphone:'',
      // useremail:'',
      // userother_phone:'',
      updateData: {
        phone: "",
        name: "",
        email: "",
        other_phone: "",
        id: "",
        code: "",
        codetext: "获取验证码",
        disabled: false, //决定获取验证码按钮是否禁用
      },
      showModal01: false,
      codetext: "获取验证码",
      phone: "",
      code: "",
      disabled: false, //决定获取验证码按钮是否禁用
      totalTime: 60,
      inputData03:'',
      inputData02:'',
      inputData01:'',
    };
  },
  created() {},
  watch: {
    boxindex(newboxindex, oldboxindex) {
      switch (newboxindex) {
        case 0:
          this.index = 0;
          break;
        case newboxindex:
          this.index = newboxindex;
          break;
        default:
          this.index = 0;
          break;
      }
    },
    userInfoData(newData, oldData) {
      if (newData) {
        this.userInfo = newData;
        this.input = newData.email;
        if (newData.cardImage == "" && newData.cardNumber == "") {
          this.card = false;
        } else {
          this.card = true;
        }
      }
    },
  },
  methods: {
    // 退出登录
    fn2(){
      this.$confirm('是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '账号已退出，请重新登录！',
          });
          this.$router.push({path:'/'})
          sessionStorage.clear()
          localStorage.removeItem('user')
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '出错了，请重新尝试'
          });          
        });
    },
    ter() {
      this.showModal01 = true;
    },
    // 发送验证码
    async obtaincode() {
      if (this.phone) {
        if (
          /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          //登录获取验证码请求
          const res = await sengOut({
            key: sessionStorage.getItem("key"),
            phone: this.phone,
          });
          this.$message({
            message: "发送成功，请注意查看手机短信",
            type: "success",
          });
          if (res.data.key) {
            await sessionStorage.setItem("key", res.data.key);
            //将获取验证码的按钮禁用并在倒计时结束之后重新启用
            this.disabled = true;
            this.codetext = this.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              this.totalTime--;
              this.codetext = this.totalTime + "s后重新发送";
              if (this.totalTime < 0) {
                window.clearInterval(clock);
                this.codetext = "获取验证码";
                this.totalTime = 60;
                this.disabled = false;
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: "您输入的手机号有误，请重新输入。",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
      }
    },
    // 校验验证码
    async loging() {
      if (this.phone && this.code) {
        //登录手机号验证码验证请求
        const res = await receive({
          key: sessionStorage.getItem("key"),
          code: this.code,
          phone: this.phone,
        });
        if (res.data.key) {
          await sessionStorage.setItem("key", res.data.key);
          await localStorage.setItem("user", JSON.stringify(res.data.user));

          let obj = JSON.parse(localStorage.getItem("user"));
          this.updateData.name = this.inputData01==""? this.userInfo.name:this.inputData01;
          this.updateData.phone = this.userInfo.phone;
          this.updateData.email = this.inputData02==""? this.userInfo.email:this.inputData02;
          this.updateData.other_phone = this.inputData03==""? this.userInfo.other_phone:this.inputData03;
          this.updateData.id = this.userInfo.id;
          // 修改用户信息接口
          const resof = await userInfoModify({
            key: sessionStorage.getItem("userkey"),
            phone: obj.phone,
            user_id: obj.id,
            mothed: "update",
            user_info: JSON.parse(JSON.stringify(this.updateData)),
          });
          if (resof.data.key) {
            obj.name = this.userInfo.name;
            obj.phone = this.userInfo.phone;
            obj.email = this.userInfo.email;
            obj.other_phone = this.userInfo.other_phone;
            await localStorage.setItem("user", JSON.stringify(obj));
            await sessionStorage.setItem("userkey", resof.data.key);
            this.$message({
              message: `修改成功！欢迎:${this.updateData.Name}`,
              type: "success",
            });
            this.showModal01 = false
            this.reload();
          } else {
            this.$message({
              message: "修改失败，请重新尝试",
              type: "info",
            });
          }
        } else {
          this.$message({
            message: "验证码输入错误!",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "手机号与验证码不能为空",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.infoBox {
  width: 100%;
  padding-top: 1.125rem /* 18px -> 1.125rem */;
  padding-bottom: 1.125rem /* 18px -> 1.125rem */;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .field {
    width: 8.125rem /* 130px -> 8.125rem */;
    display: flex;
    align-items: center;
    font-size: 1rem /* 16px -> 1rem */;
    color: #333333;
  }

  .fieldContent {
    width: 3.64375rem /* 58.3px -> 3.64375rem */;
    height: 3.64375rem /* 58.3px -> 3.64375rem */;
  }
  .fieldWord {
    font-size: 1rem /* 16px -> 1rem */;
    color: #333333;
  }
  .fieldmodify {
    font-size: 1rem /* 16px -> 1rem */;
    color: #4d89f1;
    margin-left: 7.5rem /* 120px -> 7.5rem */;
  }
  .emil {
    width: 18.75rem /* 300px -> 18.75rem */;
    height: 2.125rem /* 34px -> 2.125rem */;
  }
  .unfieldWord {
    font-size: 1rem /* 16px -> 1rem */;
    color: #b7b7b7;
  }
}
hr {
  background-color: #f0f0f0;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border: none;
}
.menuContent01 {
  width: 58.125rem /* 930px -> 58.125rem */;
  background-color: #fff;
  padding-top: 1.125rem /* 18px -> 1.125rem */;
  padding-bottom: 1.125rem /* 18px -> 1.125rem */;
  padding-left: 1.5625rem /* 25px -> 1.5625rem */;
  padding-right: 1.5625rem /* 25px -> 1.5625rem */;
  box-sizing: border-box;
  position: relative;
  .header {
    width: 100%;
    height: 3.125rem /* 50px -> 3.125rem */;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 1.125rem /* 18px -> 1.125rem */;
      color: #333333;
    }
    .outlogin {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #b7b7b7;
      display: flex;

      .out {
        width: 1.25rem /* 20px -> 1.25rem */;
        height: 1.25rem /* 20px -> 1.25rem */;
      }
    }
  }
}
.passwordbox {
  width: 18.75rem /* 300px -> 18.75rem */;
  height: 2.1875rem /* 35px -> 2.1875rem */;
  border: 0.0625rem /* 1px -> 0.0625rem */ solid #dadada;
  border-radius: 0.3125rem /* 5px -> 0.3125rem */;
  overflow: hidden;
  display: flex;
  .password {
    width: 9.375rem /* 150px -> 9.375rem */;
    height: 100%;
    padding-left: 0.625rem /* 10px -> 0.625rem */;
    padding-right: 1.25rem /* 20px -> 1.25rem */;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-right: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
    font-size: 0.875rem /* 14px -> 0.875rem */;
  }
  /* Chrome/Opera/Safari */
  .password::-webkit-input-placeholder {
    color: #bbbbbb;
  }
  /* Firefox 19+ */
  .password::-moz-placeholder {
    color: #bbbbbb;
  }
  /* Firefox 18- */
  .password:-moz-placeholder {
    color: #bbbbbb;
  }
  /* IE 10+ */
  .password:-ms-input-placeholder {
    color: #bbbbbb;
  }
  .obtain {
    padding: 0;
    text-align: center;
    flex-grow: 1;
    border: none;
    background-color: #fff;
    font-size: 1rem /* 16px -> 1rem */;
    .obtainWord {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #4d89f1;
    }
  }
}
</style>