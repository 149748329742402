import { render, staticRenderFns } from "./menuContent03.vue?vue&type=template&id=b0d01ada&scoped=true&"
import script from "./menuContent03.vue?vue&type=script&lang=js&"
export * from "./menuContent03.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./menuContent03.vue?vue&type=style&index=0&id=b0d01ada&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b0d01ada",
  "d9e6edb0"
  
)

export default component.exports