<template>
  <!-- 收藏夹 -->
    <div class="menuContent01" v-show="index==3? true:false">
        <div class="header">
          <p class="title">收藏夹</p>
          <p class="outlogin">
            <img src="../../imger/userIfon/tuichu.png" alt="" class="out">
            <span>退出登录</span>
          </p>
        </div>
        <hr>
        <div class="FavoritesBox">
            <Favorites />
        </div>
        <div class="All">
          <el-button type="danger" class="dele" round="true">删除</el-button>
          <el-checkbox v-model="checked1" label="全选" ></el-checkbox>
        </div>
      </div>
</template>

<script>
import Favorites from "./Favorites.vue";
export default {

  components: {
    Favorites,
  },
  props:['boxindex'],

  data(){
    return {
      index:this.boxindex,
    }
  },
  watch:{
    boxindex(newboxindex,oldVal){
      switch(newboxindex){
        case 0:
          this.index = 0
          break;
        case newboxindex:
          this.index = newboxindex
          break;
        default :
          this.index = 0
          break;
      }
    }
  },
}
</script>

<style scoped lang="scss">
hr{
  background-color: #F0F0F0;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border:none;
}
.menuContent01 {
    width: 58.125rem /* 930px -> 58.125rem */;
    background-color: #fff;
    padding-top: 1.125rem /* 18px -> 1.125rem */;
    padding-bottom: 1.125rem /* 18px -> 1.125rem */;
    padding-left: 1.5625rem /* 25px -> 1.5625rem */;
    padding-right: 1.5625rem /* 25px -> 1.5625rem */;
    box-sizing: border-box;
    position: relative;
    .header {
      width: 100%;
      height: 3.125rem /* 50px -> 3.125rem */;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #333333;
      }
      .outlogin {
        font-size: 0.875rem /* 14px -> 0.875rem */;
        color: #B7B7B7;
        display: flex;

        .out {
          width: 1.25rem /* 20px -> 1.25rem */;
          height: 1.25rem /* 20px -> 1.25rem */;
        }
      }
    }
    .FavoritesBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .All {
      
      height: 5rem /* 80px -> 5rem */;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .dele {
        min-height: 1.8125rem /* 29px -> 1.8125rem */;
        height: 1.8125rem /* 29px -> 1.8125rem */;
        font-size: 1rem /* 16px -> 1rem */;
        line-height: 0.3125rem /* 5px -> 0.3125rem */;
        margin-left: 1.625rem /* 26px -> 1.625rem */;
      }
    }
  }
</style>