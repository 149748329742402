<template>
  <div>
    <!-- 收件地址 -->
    <div class="menuContent01" v-show="index == 1 ? true : false">
      <div class="header">
        <p class="title">收件地址</p>
        <p class="outlogin"  @click="fn2()">
          <img src="../../imger/userIfon/tuichu.png" alt="退出登录——问标网" class="out" />
          <span>退出登录</span>
        </p>
      </div>
      <hr />
      <!-- 信息字段 -->
      <div class="infoBox02">
        <div class="fieldtitle">新增收货地址</div>
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          联系人：
        </div>
        <el-input
          v-model="inputAddress.name"
          placeholder="官方文件送达，需与您联系"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          手机号码：
        </div>
        <el-input
          v-model="inputAddress.phone"
          placeholder="请输入11位的手机号码"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          常用邮箱：
        </div>
        <el-input
          v-model="inputAddress.email"
          placeholder="重要官方文件/信息会通过邮件通知"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          收件地区：
        </div>
        <el-input
          v-model="inputAddress.region"
          placeholder="重要官方文书/通知会进行书面邮寄"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          详细地址：
        </div>
        <el-input
          v-model="inputAddress.address"
          placeholder="街道门牌详细信息"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          邮政编码：
        </div>
        <el-input
          v-model="inputAddress.postal_code"
          placeholder="请输入邮政编码"
          class="emil"
        />
      </div>
      <div class="infoBox02">
        <div class="field">
          <p class="xin">*</p>
          其他电话（选填）：
        </div>
        <el-input
          v-model="inputAddress.other_phone"
          placeholder="如座机：020-84018819"
          class="emil"
        />
      </div>

      <el-button class="button" type="primary" @click="seave">保存</el-button>

      <div class="tabywarning">
        <img src="../../imger/address/tishi02.png" alt="问标网" class="warning" />
        <p>
          已保存<span>{{ (tableDatalength = 0 ? "0" : length) }}</span
          >条地址
        </p>
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column fixed prop="name" label="收货人" />
        <el-table-column prop="phone" label="手机号码" />
        <el-table-column prop="email" label="常用邮箱" />
        <el-table-column prop="region" label="收件地区" />
        <el-table-column prop="address" label="详细地址" />
        <el-table-column prop="postal_code" label="邮政编码" />
        <el-table-column fixed="right" label="操作">
          <template #default="scope">
            <!-- 修改 -->
            <el-button
              type="text"
              size="small"
              @click="
                showModal = true;
                xiugai(scope.row);
              "
              >修改</el-button
            >
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="这是一段内容确定删除吗？"
              @confirm="delet(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                @click="visible = true"
                class="dleteButton"
                size="small"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="修改地址信息"
      :visible.sync="showModal"
      width="37.5rem"
    >
      <div class="infoBox02">
        <div class="field"><span class="xin">*</span>联系人：</div>
        <el-input
          v-model="xiugaiData.name"
          placeholder="官方文件送达，需与您联系"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>手机号码：</div>
        <el-input
          v-model="xiugaiData.phone"
          placeholder="请输入11位的手机号码"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>常用邮箱：</div>
        <el-input
          v-model="xiugaiData.email"
          placeholder="重要官方文件/信息会通过邮件通知"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>收件地区：</div>
        <el-input
          v-model="xiugaiData.region"
          placeholder="重要官方文书/通知会进行书面邮寄"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>详细地址：</div>
        <el-input
          v-model="xiugaiData.address"
          placeholder="街道门牌详细信息"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>邮政编码：</div>
        <el-input
          v-model="xiugaiData.postal_code"
          placeholder="请输入邮政编码"
          class="emil"
        ></el-input>
      </div>
      <div class="infoBox02 xiugai">
        <div class="field"><span class="xin">*</span>其他电话（选填）：</div>
        <el-input
          v-model="xiugaiData.other_phone"
          placeholder="如座机：020-84018819"
          class="emil"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            showModal = false;
            addressInfoModify();
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addressInfoAdd, addressInfoModify } from "../../API/user";

export default {
  inject: ["reload"],
  props: ["boxindex", "userAddress", "userkey", "length"],
  data() {
    return {
      visible: false, //删除地址的弹出框
      showModal: false, //修改地址的弹出对话框
      index: this.boxindex,
      tableData: this.userAddress,
      tableDatalength: this.length,
      inputAddress: {
        user_id: null,
        name: "",
        phone: "",
        email: "",
        region: "",
        address: "",
        postal_code: "",
        other_phone: "",
      },
      xiugaiData: {
        id: "",
        user_id: null,
        name: "",
        phone: "",
        email: "",
        region: "",
        address: "",
        postal_code: "",
        other_phone: "",
      },
    };
  },
  watch: {
    boxindex(newboxindex, oldVal) {
      switch (newboxindex) {
        case 0:
          this.index = 0;
          break;
        case newboxindex:
          this.index = newboxindex;
          break;
        default:
          this.index = 0;
          break;
      }
    },
    userAddress(newData, oldData) {
      if (newData !== 0) {
        this.tableData = newData;
      }
    },
    length(newData, oldData) {
      if (newData !== 0) {
        this.tableDatalength = newData;
      }
    },
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 第一步：获取收件地址输入框的内容放入一个对象
    // 第二步：将添加收件地址的接口引入
    // 第三步：将输入框的内容删除
    async seave() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.inputAddress.user_id = userData.id;
      var wear
      if (!this.inputAddress.name && !this.inputAddress.phone && !this.inputAddress.email && !this.inputAddress.region&&
      !this.inputAddress.address && !this.inputAddress.postal_code && !this.inputAddress.other_phone)
        return this.$message({
          message: "信息未填写完整",
          type: "error",
        });;
      const res = await addressInfoAdd({
        key: this.userkey,
        phone: userData.phone,
        user_id: userData.id,
        consignee_address: this.inputAddress,
      });
      if (res.data.key) {
        await sessionStorage.setItem("userkey", res.data.key);
        this.inputAddress = {
          user_id: null,
          name: "",
          phone: "",
          email: "",
          region: "",
          address: "",
          postal_code: "",
          other_phone: "",
        };
        this.$message({
          message: "保存成功",
          type: "success",
        });
        // 重新查询收件地址
        this.$emit("fn1");
      }
    },
    xiugai(data) {
      this.xiugaiData.id = data.id;
      this.xiugaiData.name = data.name;
      this.xiugaiData.phone = data.phone;
      this.xiugaiData.email = data.email;
      this.xiugaiData.region = data.region;
      this.xiugaiData.address = data.address;
      this.xiugaiData.postal_code = data.postal_code;
      this.xiugaiData.other_phone = data.other_phone;
    },

    async addressInfoModify() {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.xiugaiData.user_id = userData.id;

      const res = await addressInfoModify({
        key: sessionStorage.getItem("userkey"),
        phone: userData.phone,
        user_id: userData.id,
        mothed: "update",
        consignee_address: JSON.parse(JSON.stringify(this.xiugaiData)),
      });
      if (res.data.key) {
        await sessionStorage.setItem("userkey", res.data.key);
        for (let i = 0; i <= this.tableData.length; i++) {
          if (this.tableData[i].id == res.data.consignee_address.id) {
            this.tableData[i].name = res.data.consignee_address.name;
            this.tableData[i].phone = res.data.consignee_address.phone;
            this.tableData[i].email = res.data.consignee_address.email;
            this.tableData[i].region = res.data.consignee_address.region;
            this.tableData[i].address = res.data.consignee_address.address;
            this.tableData[i].postal_code =
              res.data.consignee_address.postal_code;
            this.tableData[i].other_phone =
              res.data.consignee_address.other_phone;
          }
        }

        this.$emit("fn1");
      }
    },

    async delet(index) {
      let userData = JSON.parse(localStorage.getItem("user"));
      const res = await addressInfoModify({
        key: this.userkey,
        phone: userData.phone,
        user_id: userData.id,
        mothed: "delete",
        consignee_address: index,
      });
      if (res.data.key) {
        await sessionStorage.setItem("userkey", res.data.key);
        this.$message({
          message: "删除成功",
          type: "success",
        });
        // 重新查询收件地址
        this.$emit("fn1");
      } else {
        this.$message({
          message: "删除失败，请重试！",
          type: "info",
        });
      }
    },
    // 退出登录
    fn2(){
      this.$confirm('是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '账号已退出，请重新登录！',
          });
          this.$router.push({path:'/'})
          sessionStorage.clear()
          localStorage.removeItem('user')
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '出错了，请重新尝试'
          });          
        });
    }
  },
};
</script>

<style scoped lang="scss">
.xiugai {
  margin-top: 1.25rem /* 20px -> 1.25rem */;
}
.xgButtom {
  display: flex;
  flex-direction: row-reverse;
}
.xin {
  color: #ff6f00;
}
hr {
  background-color: #f0f0f0;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border: none;
}
.infoBox02 {
    width: 100%;
    margin-top: 1.125rem /* 18px -> 1.125rem */;
    display: flex;
    align-items: center;
    .field {
      width: 8.75rem /* 140px -> 8.75rem */;
      display: flex;
      align-items: center;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #333333;
    }
    .emil {
      width: 20.625rem /* 330px -> 20.625rem */;
      height: 2.125rem /* 34px -> 2.125rem */;
    }
    .fieldtitle {
      height: 3.125rem /* 50px -> 3.125rem */;
      font-size: 1rem /* 16px -> 1rem */;
      color: #ff6f00;
      line-height: 3.125rem /* 50px -> 3.125rem */;
    }
  }
.menuContent01 {
  width: 58.125rem /* 930px -> 58.125rem */;
  background-color: #fff;
  padding-top: 1.125rem /* 18px -> 1.125rem */;
  padding-bottom: 1.125rem /* 18px -> 1.125rem */;
  padding-left: 1.5625rem /* 25px -> 1.5625rem */;
  padding-right: 1.5625rem /* 25px -> 1.5625rem */;
  box-sizing: border-box;
  position: relative;
  .header {
    width: 100%;
    height: 3.125rem /* 50px -> 3.125rem */;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 1.125rem /* 18px -> 1.125rem */;
      color: #333333;
    }
    .outlogin {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #b7b7b7;
      display: flex;
      cursor: pointer;
      .out {
        width: 1.25rem /* 20px -> 1.25rem */;
        height: 1.25rem /* 20px -> 1.25rem */;
      }
    }
  }
  
  .button {
    margin-top: 1.125rem /* 18px -> 1.125rem */;
    background-color: #4d89f1;
  }
  .dleteButton {
    margin-left: 0.9375rem /* 15px -> 0.9375rem */;
  }
  .tabywarning {
    width: 100%;
    height: 2.25rem /* 36px -> 2.25rem */;
    background-color: #fffaee;
    margin-top: 2.125rem /* 34px -> 2.125rem */;
    display: flex;
    align-items: center;
    font-size: 0.875rem /* 14px -> 0.875rem */;
    .warning {
      width: 0.9375rem /* 15px -> 0.9375rem */;
      height: 0.9375rem /* 15px -> 0.9375rem */;
      margin-left: 0.375rem /* 6px -> 0.375rem */;
      margin-right: 0.625rem /* 10px -> 0.625rem */;
    }
  }
}
</style>